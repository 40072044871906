<template>
  <div
    v-if="isAuthorValid"
    ref="authorBlockWrapper"
    class="text-sm md:text-base"
    :class="component.wrapper"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="authorBlockInnerRef"
      class="
        bg-white
      "
      :class="[
        component.inner,
        innerClass
      ]"
    >
      <p
        :class="component.description"
        v-html="description"
      >
      </p>

      <NuxtImg
        v-if="image"
        :src="image"
        :width="(dimensions && dimensions.width) || 192"
        :height="(dimensions && dimensions.height) || 192"
        alt="author image"
        loading="lazy"
        :class="component.image"
      />

      <p :class="component.authorTitle">
        <template v-if="theme === 'compact'">
          <strong
            v-if="!_isEmpty(_name.full)"
            class="font-bold"
          >
            {{ _name.full }}
          </strong>

          <p
            v-if="!_isEmpty(jobTitle)"
          >
            <span class="hidden md:inline-block font-bold">
              &nbsp;-&nbsp;
            </span>

            {{ jobTitle }}
          </p>
        </template>

        <strong v-if="theme === 'expanded'">
          Meet our {{ _vertical }} expert, <span class="underline">{{ _name.full }}</span>
        </strong>
      </p>

      <div
        v-if="theme === 'expanded' && !_isEmpty(_tips)"
        class="col-span-full border-t-1 border-t-gray-400 pt-4 md:pt-6"
      >
        <p class="text-lg/[1.625rem] md:text-xl mb-4 font-bold">
          {{ !_isEmpty(_name.first) ? `${_name.first}'s`: '' }} top {{ _vertical }} tips
        </p>

        <ul class="flex flex-col gap-4">
          <li
            v-for="(item, itemKey) in _tips"
            :key="`tip-${itemKey}`"
            class="flex items-start gap-x-3"
          >
            <div class="text-sm font-bold bg-dark text-white exact-wh-6 flex items-center justify-center rounded-full">
              {{ itemKey + 1 }}
            </div>

            <p v-if="!isRichText">
              {{ item }}
            </p>
            <p
              v-else
              v-html="item"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'AtomsAuthorBlock'
})

const props = defineProps({
  theme: {
    type: String,
    default: 'compact',
    validator: value => ['compact', 'expanded'].includes(value)
  },

  name: {
    type: String,
    default: ''
    // required: true
  },

  image: {
    type: String,
    default: ''
    // required: true
  },

  description: {
    type: String,
    default: ''
    // required: true
  },

  jobTitle: {
    type: String,
    default: ''
    // required: true
  },

  vertical: {
    type: String,
    default: ''
  },

  tips: {
    type: Array,
    default: () => ([])
  },

  dimensions: {
    type: Object,
    default: () => {
      return {
        width: 192,
        height: 192
      }
    }
  },

  innerClass: {
    type: String,
    default: ''
  },

  isRichText: {
    type: Boolean,
    default: false
  }
})

const validAuthors = [
  'Eayl Machlis',
  'Chris Stanley',
  'Matthew Lang',
  'Paul Coughran',
  'Anthony Stevenson'
]

const isAuthorValid = computed(() => validAuthors.includes(props.name))

const _vertical = computed(() => (props.vertical && props.vertical.toLowerCase()))

const _name = computed(() => {
  const name = props.name.split(' ')

  return {
    full: props.name,
    first: name?.[0] ?? '',
    last: name?.[1] ?? ''
  }
})

const _tips = computed(() => {
  return !_isEmpty(props.tips)
    ? props.tips.map(item => item.value)
    : []
})

const component = computed(() => {
  const wrapper = getKey(props.theme, {
    compact: 'bg-gray-350 py-10 md:py-16 px-4',
    expanded: 'w-full max-w-[40.9375rem] mx-auto'
  })

  const inner = getKey(props.theme, {
    compact: 'rounded-2.5xl max-w-wrapper-md mx-auto p-6 md:px-10 md:py-8 grid grid-cols-[3.5rem,1fr] md:grid-cols-[1fr,12rem] gap-x-3 gap-y-4 md:gap-x-10 md:gap-y-4 items-center',
    expanded: 'rounded-2xl border border-gray-400 p-4 md:pt-8 md:pl-[1.6875rem] md:pr-6 md:pb-6 grid grid-cols-[3.5rem,1fr] md:grid-cols-[5rem,1fr] gap-x-4 md:gap-x-6 gap-y-4 md:gap-y-6'
  })

  const image = getKey(props.theme, {
    compact: 'w-14 h-14 md:w-48 md:h-48 md:col-start-2 md:row-start-1 md:row-end-3',
    expanded: 'w-14 h-14 md:w-20 md:h-20 col-start-1 row-start-1'
  })

  const description = getKey(props.theme, {
    compact: 'col-span-2 md:col-span-1 min-h-[12.5rem] md:min-h-[9rem]',
    expanded: 'col-start-1 col-end-3 md:col-start-2 md:row-start-1 md:pt-[1.375rem] md:mt-4'
  })

  const authorTitle = getKey(props.theme, {
    compact: 'md:flex md:text-sm items-center',
    expanded: 'col-start-2 row-start-1 text-lg md:text-xl'
  })

  return {
    wrapper,
    inner,
    image,
    description,
    authorTitle
  }
})
</script>
