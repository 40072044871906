<template>
  <div class="py-10 lg:py-16 px-4 lg:px-0">
    <component
      :is="headerTag"
      v-if="$slots.header || title"
      class="md:text-[2rem]/[2.5rem] text-2xl md:text-center text-left md:leading-10 mb-4 md:mb-10"
      :class="headerTitleClass"
    >
      <slot name="header">
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="title"
          v-html="title"
        >
        </p>
      </slot>
    </component>

    <div
      class="max-w-4.5xl m-auto grid gap-4 md:gap-8"
      :class="contentRows"
    >
      <div
        v-for="(item, itemKey) in items"
        :key="itemKey"
      >
        <!-- eslint-disable vue/no-v-html -->
        <a
          v-if="item.url"
          :href="item.url"
          target="_blank"
          class="underline font-bold inline-block"
          :class="titleClass"
          v-html="item.title"
        >
        </a>

        <p
          v-else
          class="font-bold"
          :class="titleClass"
          v-html="item.title"
        >
        </p>

        <p
          class="text-sm"
          :class="descriptionClass"
          v-html="item.desc"
        >
        </p>
      </div>
    </div>

    <div
      v-if="$slots.footer"
      class="text-gray-80 mt-4 md:mt-10"
      :class="footerClass"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'AtomsSiteLinks'
})

const props = defineProps({
  title: {
    type: String,
    default: ''
  },

  items: {
    type: Array,
    default: () => ([])
  },

  /**
   * a flag that determines if this
   * component is being used inside contentful posts
   */
  inGuides: {
    type: Boolean,
    default: false
  },

  itemsPerRow: {
    type: [Number, String],
    default: 3
  },

  titleClass: {
    type: String,
    default: ''
  },

  headerTag: {
    type: String,
    default: 'p',
    validator: value => ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div'].includes(value)
  },

  headerTitleClass: {
    type: String,
    default: ''
  },

  descriptionClass: {
    type: String,
    default: ''
  },

  footerClass: {
    type: String,
    default: ''
  }
})

const contentRows = computed(() => {
  if (props.inGuides) {
    return 'md:grid-cols-2'
  }

  return `md:grid-cols-${props.itemsPerRow}`
})
</script>
