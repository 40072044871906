<template>
  <client-only>
    <div
      class="atoms-comparison-table pt-[2.8125rem] pb-10 md:py-16 px-4 lg:px-0"
      :class="comparisonClass"
    >
      <div
        class="max-w-wrapper-sm mx-auto"
        :class="wrapperClass"
      >
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="title"
          class="lg:text-center text-2xl md:text-3.5xl/12 mb-6 md:mb-[2.0625rem]"
          v-html="title"
        >
        </p>

        <div class="w-full mb-6 md:mb-8 overflow-hidden rounded-2xl border border-gray-400">
          <table>
            <thead>
              <tr>
                <th
                  class="!bg-blue-5 md:!bg-blue-10 "
                >
                &nbsp;
                </th>

                <th
                  v-for="(label, labelKey) in thead"
                  :key="labelKey"
                  :class="thClass"
                >
                  {{ label }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, itemKey) in tbody"
                :key="itemKey"
              >
                <td :class="tdClass">
                  {{ item }}
                </td>

                <td
                  v-for="(label, labelKey) in thead"
                  :key="labelKey"
                  :class="tdClass"
                >
                  {{
                    devices.breakpoint('sm', 'max')
                      ? costs[item][label]
                      : costs[label][item]
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="footer"
          class="lg:text-lg/[1.625rem] lg:text-center"
          v-html="footer"
        >
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup>
defineOptions({
  name: 'AtomsHomeComparisonTable'
})

const { devices } = deviceChecker()

const props = defineProps({
  title: {
    type: String,
    default: ''
  },

  footer: {
    type: String,
    default: ''
  },

  costs: {
    type: Object,
    default: () => {
      return {
        QLD: {
          'Couple 50+': 439.23,
          Singles: 219.61
        },
        VIC: {
          'Couple 50+': 439.23,
          Singles: 219.61
        }
      }
    }
  },

  comparisonClass: {
    type: String,
    default: ''
  },

  wrapperClass: {
    type: String,
    default: ''
  },

  thClass: {
    type: String,
    default: ''
  },

  tdClass: {
    type: String,
    default: ''
  }
})

const thead = computed(() => {
  const costs = props.costs
  const costsKeys = Object.keys(costs)

  return devices.breakpoint('sm', 'max')
    ? Object.keys(costs[costsKeys[0]])
    : costsKeys
})

const tbody = computed(() => {
  const costs = props.costs
  const costsKeys = Object.keys(costs)

  return devices.breakpoint('sm', 'max')
    ? costsKeys
    : Object.keys(costs[costsKeys[0]])
})
</script>

<style lang="scss" src="./index.scss" scoped></style>
