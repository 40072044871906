
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93bHz1QphM0KMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as index8OIdtqM4KPMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesFrwEenXbAkMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_931S15IrEqH1Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexI2E1gCRLwVMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbacksGF58fgVJjMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usiZ3UVFNPmHMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackkou3UXw0XYMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergec76MbrxYVqMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimfES9z8kAWPMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditionswCYupZ5XltMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveQck6XQRJ24Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexfJyIvhiFW3Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93nE7mdU0XbpMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as details1cJuvoKjQRMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsqQ5KSXhKnZMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexuLsZ4yPi4ZMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexTxH40LvAzuMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticedD9DOC6nZdMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsUjbXbYH00AMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us5rhqueVgvrMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93GdjzeVQI5YMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexVdeZRIiYwWMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_938saPZNqY2ZMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93PeiVZ5kK0wMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsY8UhCVMRBPMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexwUYpaTBk9lMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offer3PaWSUnWr0Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexD5vbxH9d46Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as index2maPyUclnvMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexxjdkSgEjxbMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as index1G9Q7dbFvGMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45details3lN3WLMvU7Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as completionXWJ777Om8AMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as detailssG0Xw7vEBEMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as _91step_93rK9WFUzP50Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93zw3lYKJQhbMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93NlZVeBGfS6Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93eeqHYDxOzsMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93mkKj2frfmxMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailskYS3ztonFeMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as rebatebly1w2gRaoMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as digital_45eic639J9zYoNuMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorXC9zZpqxljMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45your3Yz5WmXFPMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as coffs_45harbour7xZ4xZ3n6dMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as indexSuTG1rLKuUMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93rIUp9cJ46FMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsZ4jIiifEVFMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youVxRNs6wEjZMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93XoZ0QLdVogMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexwzqj0NSirJMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93GJQ4xpJyMKMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexfC3u6Yr9hUMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passworddlrqlP07LfMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as set_45passwordRUuOC5SkmMMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backut1egLyMYJMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as _91step_93nL6nFHYm48Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredwwwrdJh1XiMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexTFnWl3Kj1nMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexwXh0RFE2SHMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as errorlEfOOGhzPkMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as index9hZRhXrtM2Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45betZ00IUdjtMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatsGkV1JAIUXMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexn224P9dCdbMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addui72zDWGQlMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupPHOFgErOvHMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleCeUhPwMrIwMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexVmDWTLiVO3Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_935WwOHbL5DYMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsdDDsMbyDLeMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0XLabADDn1rMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as detailsh9mUWEhrXiMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as _91step_93VO2EFm2p0ZMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as step0G0Woc4tpGfMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as index8SXsW2XE2pMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youQFhiIfcuqxMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as index5pQX81qvCvMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexmN5B0FaGEeMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexM0vqNQDp6tMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexDCPQjnOGYIMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93fB2P8B0Wu7Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93ZG7WTkd6p9Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmation5L24vWZLGTMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsmkEckzgDaQMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexN5MN9AOS3PMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsb38IcfqNbXMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youobNt8xuc6uMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_93gi20Vv63XEMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexiztym0NjW1Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexypBrw6y1kJMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesE4t5RZrTWsMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93VdFvJAzcT0Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsKHkt0KGwXiMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youXKANrKCek6Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as index9pZPDFhTefMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customersZvXesIamm9Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationEIEnixSICQMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailsbKOGzYWqtDMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexH71XhKq96FMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexCe4LFE4ozIMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addLNh1l7D9IOMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youYQhCYGINvLMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexsvOjkxK939Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successZKOi5kH6F3Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93E3DDUIKl4MMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsf0wajIVsfmMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0jebVaTrwGFMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as loginpsX5B3eevRMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93kAHdt98fVJMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93iPeVpCnzLxMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexezj0BY7QCQMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsHpzSoI6k7QMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexELqKn3lTwrMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93icAI6ZvrdPMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsS2dzg1GgJ2Meta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youIzYjpedfXeMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youd0IlHbZykaMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_932x9UNDBhfxMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93YG31mriuFwMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_931lCMCl8mtxMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyCNjwez51fSMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerbHhkaYwmneMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordP1UgJzYbucMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as terms_45of_45useNakqJcTDHgMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyq6zSXO0bohMeta } from "/codebuild/output/src2144124217/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbacksGF58fgVJjMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93GdjzeVQI5YMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsY8UhCVMRBPMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexwUYpaTBk9lMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer3PaWSUnWr0Meta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexD5vbxH9d46Meta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: index2maPyUclnvMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexxjdkSgEjxbMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index1G9Q7dbFvGMeta?.props ?? {"verificationNeeded":true},
    meta: index1G9Q7dbFvGMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product(.*)*/:vertical()/additional-details",
    meta: additional_45details3lN3WLMvU7Meta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product(.*)*/:vertical()/completion",
    meta: completionXWJ777Om8AMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product(.*)*/details",
    meta: detailssG0Xw7vEBEMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product(.*)*/form/energy/step/:step()",
    meta: _91step_93rK9WFUzP50Meta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product(.*)*/form/generic/step/:step()",
    meta: _91step_93zw3lYKJQhbMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/health-insurance/step/:step()",
    meta: _91step_93NlZVeBGfS6Meta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product(.*)*/form/home-loan/step/:step()",
    meta: _91step_93eeqHYDxOzsMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/life-insurance/step/:step()",
    meta: _91step_93mkKj2frfmxMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product(.*)*/lic-details",
    meta: lic_45detailskYS3ztonFeMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product(.*)*/rebate",
    meta: rebatebly1w2gRaoMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passworddlrqlP07LfMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordRUuOC5SkmMMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93VO2EFm2p0ZMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginpsX5B3eevRMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerbHhkaYwmneMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordP1UgJzYbucMeta || {},
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2144124217/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]